<template>
  <div>
    <div class="card-wrapper">
      <p class="back" @click="$router.back()"><i class="el-icon-back"></i> 返回</p>
      <div class="card">
        <p class="title">基本资料 </p>
        <div class="card-left">

          <el-form label-width="100px" :inline="true">
            <el-form-item label="用户ID:">
              <el-input
                disabled
                v-model="detail.uuid"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户全称:">
              <el-input v-model="detail.name" disabled size="small"></el-input>
            </el-form-item>
            <el-form-item label="登录账号:">
              <el-input v-model="detail.phone" disabled size="small"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" plain @click="openAllocate" size="small">分配设备</el-button>
              <el-button type="primary" plain @click="handleEnterpriseChange" size="small">代为运营</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="card card-board">
        <p class="title">
          开通模块
          <span style="position: relative;float: right;padding-left: 10px;">
            <i class="dot"></i>
            代表收费模块
          </span>
        </p>
        <ul class="models">
          <li
            class="add-btn"
            @click="modelsvisible = true"
          >
            <span class="el-icon-plus"></span>
          </li>
          <li
            v-for="(item,index)  in hasownModels"
            :key="item.uuid"
            :class="{fee: item.isFree!= 1}"
          >
            <span
              :class="item.icon"
              :style="{color: item.color}"
            ></span>
            <p><span class="dot"></span>{{item.name}}</p>
            <div
              class="close"
              @click="handleClose(item, index)"
            >
              <i class="el-icon-close"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      :visible.sync="modelsvisible"
      width="540px"
      title="开通模块"
      @open="handleOpen"
    >
      <el-transfer
        filterable
        v-model="models"
        :data="modelList"
        :props="{
          key: 'uuid',
          label: 'name'
        }"
        :titles="['未开通','已开通']"
        style="display: block;margin: 0 auto;"
      ></el-transfer>
      <div slot="footer">
        <el-button
          type="primary"
          @click="handleConfirm"
        >保存
        </el-button>
      </div>
    </el-dialog>

    <c-dialog class="dialog" :visible.sync="allocatedVisible" :width="'50rem'" :height="'37.5rem'"
              :outer-close="false"
              title="分配设备">

      <el-button slot="title" size="mini" type="primary" @click="deviceAllocate">保存</el-button>

      <allocation-device :close="closeAllocated" ref="allocDevice" slot="default"></allocation-device>
    </c-dialog>
  </div>
</template>

<script>
  import {
    moduleOpen,
    moduleClose, enterpriseModules,enterpriseCtrl
  } from '@/api/factory/account/enterprise'


  import {moduleList} from '@/api/factory/application/module'
  import CDialog from '@/components/Dialog'
  import AllocationDevice from '@/components/AllocateDevice'
  import {setToken} from '@/util/auth'
  export default {
    data() {
      return {
        allocatedVisible: false,
        modelsvisible: false,
        hasownModels: [],
        models: [],
        modelList: [],
        detail: {},
      }
    },
    methods: {
      init() {
        let detail = this.$route.params.enterprise

        if (!detail || !detail.uuid) {
          this.$router.back()
          return
        }

        this.detail = detail

        enterpriseModules({uuid: detail.uuid}).then(res => {
          if (res.code === 0) {
            let moduleSorts = res.data || []
            this.hasownModels = []
            moduleSorts.forEach(moduleSort => {
              let modules = moduleSort.modules || []
              modules.forEach(module => {
                this.hasownModels.push(module)
              })
            })
          }
        })
      },
      handleOpen() {
        moduleList().then(res => {
          if (res.code === 0) {
            this.modelList = res.data || []
            this.models = this.hasownModels.map(v => v.uuid)
          }
        })
      },
      handleEnterpriseChange() {
        enterpriseCtrl({uuid: this.detail.uuid}).then(res => {
          setToken(res.data)
          window.location.href = "/"
        })
      },
      handleConfirm() {
        let uuid = this.detail.uuid
        moduleOpen(uuid, {moduleUuids: this.models}).then(res => {
          if (res.code === 0) {
            this.$message.success('开通成功')
            this.init()
            this.modelsvisible = false
          }
        })
      },
      handleClose(item, index) {
        let {uuid} = item
        let targetUuid = this.detail.uuid
        this.$confirm('是否确认关闭模块：' + item.name, '提示', {type: 'warning'}).then(() => {
          moduleClose({uuid: targetUuid, moduleUuid: uuid}).then(res => {
            if (res.code === 0) {
              this.$message.success('关闭成功')
              this.hasownModels.splice(index, 1)
            }
          })
        }).catch(() => {
          this.$message('已取消')
        })
      },
      closeAllocated(){
        this.allocatedVisible = false
      },
      openAllocate(){
        let vue = this
        this.allocatedVisible = true
        this.$nextTick(function () {
          vue.$refs.allocDevice.setEnterprise(this.detail)
        }, 200)
      },
      deviceAllocate () {
        let vue = this
        vue.$refs.allocDevice.deviceAllocate()
      }
    },
    mounted() {
      this.init()
    },
    activated() {
      this.init()
    },

    components: {
      CDialog,
      AllocationDevice
    }
  }
</script>

<style lang="scss">
  .card-wrapper {
    .card {
      .el-form-item {
        margin-bottom: 35px;
      }

      .el-form-item__label {
        font-size: 12px;
        color: #999;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .card-wrapper {
    padding: $padding;

    .back {
      cursor: pointer;
    }

    .back:hover {
      color: $_theme_color;
    }

    .card-board {
      box-shadow: $-box-shadow;
    }

    .card {
      border-radius: $border-radius;
      position: relative;
      color: $font-color-shallow6;
      padding: $padding;

      .save-btn {
        position: absolute;
        right: 35%;
        bottom: $padding;
      }

      .title {
        font-size: 12px;
        margin-bottom: $margin;
      }

      span {
        color: #333;
      }

      .text {
        min-height: 30px;
        padding-left: 90px;
        position: relative;
        line-height: 30px;
        font-size: 12px;
        color: #333;

        span {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          color: #999;
          text-align: right;
          width: 80px;
        }
      }


      .dot {
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $-theme-color;

        position: absolute;
        left: 0px;
        top: 6px;
      }

      .models {
        li {
          width: 80px;
          height: 80px;
          float: left;
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: $border-radius;
          padding-bottom: 20px;
          text-align: center;
          line-height: 70px;
          position: relative;
          cursor: pointer;
          transition: all 0.2s linear;

          &.fee {
            p {
              .dot {
                display: block;
              }
            }
          }

          &:hover {
            transform: scale(1.2);
            background-color: #f8f8f8;

            .close {
              display: block;
            }
          }

          p {
            position: absolute;
            bottom: 0;
            line-height: 20px;
            text-align: center;
            width: 100%;
            font-size: 12px;
            color: #707070;

            .dot {
              display: none;
              left: 3px;
              top: 8px;
            }
          }

          .iconfont {
            font-size: 35px;
            color: $-theme-color;
          }

          &.add-btn {
            line-height: 80px;
            padding-bottom: 0;
            font-size: 30px;

            .iyy-icon-plus {
              color: #999;
            }
          }

          .close {
            width: 22px;
            height: 22px;
            background-color: $-theme-light-5;
            line-height: 22px;
            border-radius: 50%;
            position: absolute;
            right: -11px;
            top: -11px;
            display: none;
            opacity: 0.7;
            color: #999;
            font-size: 20px;
            text-align: center;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .card:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
</style>
