import request from '@/util/request'

//获取分类列表
export const categories = () => {
  return request({
    url: '/api/v1/system/moduleSort/list',
    method: 'get'
  })
}
/**
 * 新增模块分类
 * @param name
 * @param icon
 * @param color
 * @param sysRoleAcLevel (1|3|5|7|9|11)
 * @param content
 */
export const addCategory = ({name, icon, color, sysRoleAcLevel, content}) => {
  return request({
    url: '/api/v1/system/moduleSort/create',
    method: 'post',
    data: {
      name,
      icon,
      color,
      sysRoleAcLevel,
      content
    }
  })
}

/**
 * 更新模块分类
 * @param uuid
 * @param name
 * @param icon
 * @param color
 * @param content
 * @param sysRoleAcLevel
 * @param order
 */
export const updateCategroy = ({uuid,name,icon,color,content,sysRoleAcLevel,orderCond}) => {
  return request({
    url: '/api/v1/system/moduleSort/update',
    method: 'post',
    data: {
      uuid,
      name,
      icon,
      color,
      sysRoleAcLevel,
      content,
      orderCond
    }
  })
}

/**
 * 模块分类发布
 * @param sortUuid
 */
export const publishCategory = ({sortUuid}) => {
  return request({
    url: '/api/v1/system/moduleSort/publish',
    method: 'post',
    params: {
      sortUuid
    }
  })
}

/**
 * 新增模块分类套餐
 * @param moduleSortUuid
 * @param name
 * @param price
 * @param timeLength
 */
export const addCategoryPackage = ({moduleSortUuid,name,price,timeLength}) => {
  return request({
    url: '/api/v1/system/moduleSort/addCompose',
    method: 'post',
    data: {
      moduleSortUuid,name,price,timeLength
    }
  })
}

export const moduleList = (params) => {
  return request({
    url: '/api/v1/system/module/list',
    method: 'get',
    params: params
  })
}
/**
 * 修改模块
 * @param moduleSortUuid
 * @param name
 * @param icon
 * @param oreder
 * @param isFree
 * @param sysRoleAcLevel
 * @param content
 * @param webRouterName
 * @param mobilePage
 * @param canUse
 * @param order
 */
export const addModule = ({moduleSortUuid,name,icon,order,isFree,sysRoleAcLevel,
                            engName, content,webRouterName,mobilePage,canUse,color}) => {
  return request({
    url: '/api/v1/system/module/create',
    method: 'post',
    data: {
      moduleSortUuid,
      name,
      icon,
      isFree,
      sysRoleAcLevel,
      content,
      webRouterName,
      mobilePage,
      engName,
      canUse,
      color,
      order
    }
  })
}
/**
 * 修改模块细腻
 * @param moduleSortUuid
 * @param name
 * @param icon
 * @param oreder
 * @param isFree
 * @param sysRoleAcLevel
 * @param content
 * @param webRouterName
 * @param mobilePage
 * @param canUse
 * @param order
 */
export const updateModule = ({uuid,moduleSortUuid,name,icon,color,oreder,isFree,sysRoleAcLevel,
                               content,webRouterName,mobilePage,canUse,order, engName}) => {
  return request({
    url: '/api/v1/system/module/update',
    method: 'post',
    data: {
      uuid,
      moduleSortUuid,
      name,
      icon,
      oreder,
      isFree,
      sysRoleAcLevel,
      content,
      webRouterName,
      mobilePage,
      canUse,
      order,
      color,
      engName,
    }
  })
}

export function moduleOpen(moduleUUID) {
  return request({
    url: `/api/v1/system/module/open?uuid=${moduleUUID}`,
    method: 'get',
  })
}
